<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-01-21 14:45:15
 * @LastEditors: ------
 * @LastEditTime: 2022-02-21 10:12:10
-->
<template>
  <div class="login">
    <van-image
      width="100%"
      fit="contain"
      :src="require('../../images/login_bg.png')"
    />
    <div class="loginType">
   
       
          <van-row style="margin: 26px">
            <van-col span="2"></van-col>
            <van-col span="20">
              <van-form @submit="onSubmit()" class="myForm">
                <van-field
                  v-model="username"
                  name="用户名"
                  label="用户名"
                  placeholder="请填写手机号"
                  autofocus
                  clearable
                  center
                  size="large"
                  autocomplete
                  label-width="2rem"
                  :left-icon="require('../../images/icon-sj.png')"
                  :rules="[{ required: true }]"
                />
                <van-field
                  v-model="password"
                  type="password"
                  name="密码"
                  label="密码"
                  placeholder="请填写密码"
                  clearable
                  autocomplete
                  center
                  label-width="2rem"
                  size="large"
                  :left-icon="require('../../images/icon-mm.png')"
                  :rules="[{ required: true }]"
                />
                
                <div class="button">
                  <van-button round block type="info" native-type="submit"
                    >登录</van-button
                  >
                </div>
                <div class="a">
                  
                  <a @click="$router.push('/register')">没有账号，去注册 ! ! !</a>
                </div>
              </van-form>
            </van-col>
            <van-col span="2"></van-col>
          </van-row>
      
         <!-- <van-tab title="验证码登录">
          
          <van-row style="margin: 26px">
            <van-col span="2"></van-col>
            <van-col span="20">
              <van-form @submit="onSubmit()" class="myForm">
               <van-field
                  v-model="username"
                  name="手机号"
                  label="手机号"
                  placeholder="请填写手机号"
                  autofocus
                  clearable
                  center
                  size="large"
                  autocomplete
                  label-width="2rem"
                  :left-icon="require('../../images/icon-手机.png')"
                  :rules="[{ required: true }]"
                />
                
                <van-cell-group>
                  <van-field
                    v-model="authCode"
                    center
                    clearable
                    label="验证码"
                    placeholder="请输入验证码"
                    autocomplete
                    label-width="2rem"
                    :left-icon="require('../../images/icon-yzm.png')"
                    :rules="[{ required: true }]"
                  >
                   <template #button>
                      <van-button size="small" type="primary" class="button2" @click="onAuthCode">点击获取</van-button>
                    </template>
                  </van-field>
                </van-cell-group> 
                <div class="button">
                  <van-button round block type="info" native-type="submit"
                    >提交</van-button
                  >
                </div>
              </van-form>
            </van-col>
            <van-col span="2"></van-col>
          </van-row>
        </van-tab>-->
    
    </div>
  </div>
</template>

<script>
import { verifyCode } from "@/api";

export default {
  name: "",
  data() {
    return {
      active: 0,
      username: "",
      password: "",
      authCode: "",
    };
  },
  methods: {
    onSubmit() {
      this.$store.dispatch("actLogin", {
        username: this.username,
        password: this.password,
      });
    },
    go(){

    },
    onAuthCode(){
      if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.username.mobile))) {
          alert('请输入11位有效手机号');
				}
          verifyCode(this.username).then((res) => {
        if (res.data.success) {
          this.total = res.data.result.total || 0;
          this.list = res.data.result.records;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
button {
  cursor: pointer;
}
.login {
  .loginType {
    margin-top: 20px;
    /deep/ .van-tab__text {
      font-size: 18px;
    }
   
    .myForm {
      margin-top: 30px;
      /deep/ .van-field {
        height: 50px;
        font-size: 20px;
        .van-icon {
          width: 22px;
          height: 22px;
          .van-icon__image {
            width: 100%;
            height: 100%;
          }
        }
        .van-field__label {
          font-size: 20px;
        }
        .van-cell-group--inset {
          margin:0px;
        }
      }
    }
  }
  .button {
    margin-top: 30px;
    /deep/ .van-button__text {
      font-size: 22px;
    }
  } 
  .button2{
    width: 68px;
    font-weight:bold;
    height: 24px;
    background:white;
    Color:#4731FA;
    text-align:center;
    font-size: 5px;
    border: 1px solid #4731FA;
    border-radius: 12px;
  }
   .a{
      font-size: 18px;
      color: #1476FF;
      text-align:center;
      margin-top: 20px;
    }
}
</style>
